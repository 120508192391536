import { SET_BANNER_ENABLED_STATUS } from "../../constants/bioCatch";

const initialstate = {
    isBannerEnabled: false,
};

const bannerSwitch = (state = initialstate, action) => {
    switch (action.type) {
        case SET_BANNER_ENABLED_STATUS: {
            return { ...state, isBannerEnabled: action.data };
        }
        default:
            return state;
    }
};

export default bannerSwitch;