import axiosClient from '../shared/utils/axiosClient';
import * as EndPoints from '../constants/Urls';
import { setBannerEnabledStatus } from '../redux/actions/bannerSwitchAction';

const BannerKillSwitchService = {
    getBannerKillSwitchForBioCatch: async (dispatch) => {
        await axiosClient.get(EndPoints.bioCatchBannerKillSwitch)
            .then(response => {
                if (response.data.data.isEnabled) {
                    dispatch(setBannerEnabledStatus(response.data.data.isEnabled));
                }
            })
            .catch(error => {
                if (error) {
                    dispatch(setBannerEnabledStatus(false));
                }
            });
    }
}

export default BannerKillSwitchService;