export const loginUserUrl = 'api/logon/v1/logon';
export const supportUrl = 'api/logon/v1/perfmon';
export const redirectToRegisterUrl = '/registration/client/registration/RegSelectionDoAction.aspx';
export const loginUrl = '/Business/login';

// Please remove 'api/' from below paths to test the endpoints locally via iisexpress
export const content = 'api/content/v1/content/{utility}';
export const getDBKillSwitch = 'api/dbkillswitch/v1/kill-switch/{killSwitchName}';
export const switchesUrl = 'api/logon/v1/switches';
export const bioCatchBannerKillSwitch = 'api/dbkillswitch/v1/IsBiocatchBannerEnabled';
